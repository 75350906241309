import React, { useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import {
  Box, Flex, Text, Input, Spinner, Center,
} from '@chakra-ui/react';
import { logEvent } from '../util';

const AutoComplete = ({
  query,
  queryVariableName,
  suggestionResultCallback,
  suggestionDisplayField,
  result,
  setResult,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [getQueryData, { loading, data }] = useLazyQuery(query);
  const suggestions = useRef([]);

  if (data) {
    suggestions.current = suggestionResultCallback(data);
  }

  const getSuggestionValue = suggestion => suggestion[suggestionDisplayField];

  const renderSuggestionContainer = ({ containerProps, children, query }) => (
    <Box
      bg="white"
      maxHeight="200px"
      overflowY="scroll"
      position="absolute"
      w="100%"
      borderTop="0"
      {...containerProps}
    >
      {loading ? <Center><Spinner size="sm" /></Center> : children}
    </Box>
  );
  const renderSuggestion = suggestion => (
    <Text
      borderBottom="solid 1px"
      borderColor="gray.200"
      p="2"
      _hover={{
        bg: 'gray.100',
      }}
    >
      {suggestion[suggestionDisplayField]}
    </Text>
  );

  const renderInputComponent = inputProps => (
    <Input variant="flushed" size="lg" _placeholder={{ color: 'gray.700' }} {...inputProps} />
  );

  const onChange = (event, { newValue }) => {
    setInputValue(newValue);
    if (newValue === '') {
      setResult({});
    }
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    logEvent('Occupation_Selected', suggestion.name, inputValue);
    setResult(suggestion);
  };

  const requery = value => {
    getQueryData({ variables: { [queryVariableName]: value } });
  };

  const debounceRequery = useRef(debounce(requery, 150)).current;

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    if (reason === 'input-changed') {
      suggestions.current = [];
      if (value !== '') {
        debounceRequery(value);
      }
    }
  };

  const onSuggestionsClearRequested = () => {
    suggestions.current = [];
  };

  const inputProps = {
    placeholder,
    value: inputValue,
    onChange,
  };

  return (
    <Box position="relative">
      <Autosuggest
        suggestions={suggestions.current}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionContainer}
        renderInputComponent={renderInputComponent}
        inputProps={inputProps}
      />
    </Box>
  );
};

export default AutoComplete;
